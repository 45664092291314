import React from "react";
import InternalLayout from "../layouts/internallayout";
import styled from "styled-components";
import Seo from "../components/seo";


const Container = styled.div`
    width: 100%;
    max-width: 1150px;
    margin: 0 auto;
    padding: 5em 1em;
    position: relative;
    overflow: hidden;
`
const Fees = () => (
    <InternalLayout>
        <Container>
            <Seo title="Frequently asked questions about Element Physiotherapy's fees" />
            <main>
                <h1>Fees</h1>
                <h2>Fees and billing arrangements</h2>
                <p>Fees are payable at the time of consultation. We accept cash, cheque, EFTPOS, Mastercard, or Visa.
                    Alternatively, we can accept credit card payments over the phone.</p>
                <p>Please speak to our reception team regarding claims relating to Workers’ Compensation, Motor Vehicle
                    Accidents and Veterans’ Affairs.</p>
                <h2>Do you accept all health funds?</h2>
                <p>Yes, we have HiCaps facilities available for your convenience. HiCaps accepts all major health funds.
                    Please bring your health fund card on the day of your appointment in order for us to process your
                    refund.</p>
                <h2>How much will I receive back from my health provider?</h2>
                <p>All health funds rebate differently and we would recommend discussing benefits with your fund. Our
                    reception team can supply the Item Numbers relating to the service to assist you with these
                    discussions.</p>
                <h2>Are you a Medibank, HBF or Bupa preferred provider?</h2>
                <p>Our Physiotherapy service is a private practice and we work collaboratively with all health funds,
                    therefore in fairness to all of our patients, we have opted not to become a preferred provider for
                    individual funds.</p>
                <h2>Does Medicare cover physiotherapy?</h2>
                <p>Unfortunately, Medicare does not cover physiotherapy. The only exception relates to referrals from a
                    General Practitioner for a multidisciplinary Care Plan - (EPC) Plan.</p>
                <h2>Does private health insurance cover my appointments?</h2>
                <p>Our practice accepts all health funds and provide Hicaps facilities to allow you to swipe your card
                    and claim directly from the health fund on the day, meaning you only have to pay the gap.</p>
                <p>Due to the large number of health funds and insurance products, we are unable to provide exact
                    details regarding your out of pocket expenses.</p>
                <p>Contact us to obtain a quote on the exact services you require. This will give you all the required
                    information so that you can call your health fund and they can determine your exact out of pocket
                    expense.</p>
                <h2>Do we accept care plans? (EPC)</h2>
                <p>Medicare cardholders may be eligible for an <a
                    href="https://www1.health.gov.au/internet/main/publishing.nsf/Content/mbsprimarycare-chronicdiseasemanagement"
                    target="_blank">Chronic Disease Management referral</a> (EPC Plan), however this must be obtained by
                    a General Practitioner. This referral can allow up to five physiotherapy visits per calender year.
                    When using a Chronic Disease Management referral we only charge a gap fee of $31.20 for each
                    consultation. This $31.20 covers the cost of your consultation and use of practice equipment and
                    consumables such as massage creams, dry needles, thera-band, and taping if required.</p>
                <h2>I have a work-related injury or have been in a car accident. Should I go to a physiotherapist?</h2>
                <p>All Workcover and CTP claims in NSW are covered by individual insurers. The governing body for
                    Workcover being SIRA and governing body for all third party/motor vehicle accidents being the
                    RMS.</p>
                <p>Physiotherapists are first-line practitioners for most work-related and motor vehicle injuries. You
                    can discuss with your GP whether Physiotherapy or Exercise Physiology services are right for
                    you.</p>
                <p>Please be aware that you will be required to pay for services at the time of consultation if you have
                    not yet received a nominated insurer and claim number.</p>
                <p>Once you provide your insurer and claim number details all treatment will be fully covered by your
                    insurer meaning no out of pocket expense for treatment services.</p>
                <h2>Does Element Physio accept NDIS patients?</h2>
                <p>Yes, We are registered providers with the NDIS to support those who would benefit from greater time
                    and attention.</p>
                <p>Some of our patients prefer to tailor their own treatment plan with us directly while others prefer
                    the use of an external provider.</p>
                <h2>Our services are accessible for both Physiotherapy &amp; Exercise Physiology. </h2>
                <p>We provide comprehensive assessments, specific and meaningful goal setting for our patients and
                    reporting back to referring providers.</p>
                <h2>If I can’t make an appointment, do you have a cancellation policy?</h2>
                <p>This clinic has a 24-hour notice cancellation policy. By making an appointment with us, you agree to
                    turn up to your appointment on time or pay a cancellation fee of $70 (if less than 8 business hours
                    notice or failure to attend) or $20 (if less than 24 hours notice is given). These cancellation fees
                    are charged to cover the time your physiotherapist has allocated to you. Read the full <a href="/cancellation-policy">Cancellation Policy</a>.</p>
            </main>
        </Container>

    </InternalLayout>
)
export default Fees
